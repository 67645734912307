import React, { useState } from 'react'
import { H2, styled, View, XStack, YStack } from 'tamagui'
import { PillCarousel, PillItem } from '@red-ui/components'
import { Content } from '@/components'

type DiscoveryHeaderProps = {
  title: string
  categories?: Array<PillItem>
}

const InnerShadow = styled(View, {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '$2',
  pointerEvents: 'none',
  backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.075), transparent)',
  zIndex: 1,
})

export const DiscoveryHeader = ({ title, categories }: DiscoveryHeaderProps) => {
  const [selected, setSelected] = useState<PillItem[]>([])

  const onPress = (filter: PillItem) => {
    if (filter.name === 'All') {
      setSelected([])
      return
    }
    setSelected((prevSelected) => {
      const index = prevSelected.findIndex((item) => item.name === filter.name)
      if (index === -1) {
        return [...prevSelected, filter]
      }
      return prevSelected.filter((item) => item.name !== filter.name)
    })
  }

  return (
    <YStack backgroundColor={'$red6'} paddingVertical={'$4'} paddingHorizontal={'$6'} position="relative">
      <InnerShadow />
      <Content>
        <H2 color={'$white'} margin={'$0.5'} fontWeight={500} paddingBottom={'$1'}>
          {title}
        </H2>
        <XStack height={'$2'}></XStack>
        {categories && (
          <PillCarousel
            items={categories}
            onPress={onPress}
            selected={selected.length === 0 && categories[0] ? [categories[0]] : selected}
          />
        )}
      </Content>
    </YStack>
  )
}
