/**
 * Formats a date object to a string in the format "Year-Month-Day"
 * @param date - The date object to format
 * @returns A string in the format "Year-Month-Day"
 */
export function formatDateToShortFormat(date: Date) {
  const [day, month, year] = Intl.DateTimeFormat('en-UK', { month: 'numeric', day: 'numeric', year: 'numeric' }).format(date).split('/')

  return `${year}-${month}-${day}`
}

/**
 * Formats a date object to a string in the format "Day Month, Year"
 * @param date - The date object to format
 * @returns A string in the format "Day Month, Year"
 */
export function formatDateForDisplay(date: Date) {
  const [day, month, year] = Intl.DateTimeFormat('en-UK', { month: 'long', day: 'numeric', year: 'numeric' }).format(date).split(' ')

  return `${day} ${month}, ${year}`
}
