import React from 'react'
import { Accordion, AnimatePresence, YStack, ScrollView, XStack, useMedia, Separator, Text, Icon } from '@red-ui/components'
import { Header } from './Header'
import { SeatTypeCard } from './SeatTypeCard'
import { getTrackingId } from '../../helpers/getTrackingId'
import { formatPoints } from '@vrw/data/src/utils/formatters'
import { getString } from '@vrw/data'

export type Props = {
  value: string
  isActive: boolean
  onSelect: (flightId: string) => void
}

export const fares = [
  {
    fareId: '1',
    cabinName: 'Economy Classic',
    points: 100000,
    availableSeatCount: 3,
  },
  {
    fareId: '2',
    cabinName: 'Premium',
    points: 130000,
    availableSeatCount: 9,
  },
  {
    fareId: '3',
    cabinName: 'Upper Class',
    points: 270000,
    availableSeatCount: null,
  },
]

export const FlightDataCard = ({ value, isActive, onSelect }: Props) => {
  const { gtTabletPortrait } = useMedia()

  const seatTypeCards = (
    <>
      <SeatTypeCard onSelect={() => onSelect(value)} />
      <SeatTypeCard onSelect={() => onSelect(value)} />
      <SeatTypeCard onSelect={() => onSelect(value)} />
    </>
  )

  return (
    <Accordion.Item
      value={value}
      backgroundColor="$white"
      borderWidth={1}
      borderRadius="$4"
      borderColor={isActive ? '$black10' : '$black2'}>
      {/* ui lib auto makes a h1 tag for the accordion header, so we need to use a div tag */}
      <Accordion.Header tag="div" width="100%">
        <Accordion.Trigger
          id={getTrackingId('flight-card')}
          borderRadius="$4"
          cursor="pointer"
          backgroundColor="$white"
          unstyled
          width="100%"
          hoverStyle={{ backgroundColor: '$white' }}
          focusStyle={{ backgroundColor: '$white' }}>
          <YStack padding="$2" $gtMobile={{ padding: '$3' }} width="100%">
            <Header />
            {!isActive && (
              <>
                <Separator marginVertical="$2" $gtMobile={{ marginVertical: '$3' }} />
                <XStack gap="$2" overflow="scroll">
                  {fares.map((fare) => {
                    const isAvailable = fare.availableSeatCount != null
                    const isLowAvailability = isAvailable && fare.availableSeatCount < 9

                    return (
                      <XStack
                        key={fare.fareId}
                        borderWidth={1}
                        backgroundColor={isAvailable ? '$pink5' : '$white'}
                        borderColor={isAvailable ? '$transparent' : '$black2'}
                        padding="$2"
                        flexGrow={1}
                        flexBasis="0%"
                        justifyContent="space-between"
                        borderRadius="$4"
                        alignItems="center"
                        minWidth={284}>
                        <YStack alignItems="flex-start" gap="$1">
                          <Text fontSize="$4" $gtMobile={{ fontSize: '$5' }} $gtTabletLandscape={{ fontSize: '$6' }}>
                            {fare.cabinName}
                          </Text>
                          <XStack alignItems="center" gap="$1">
                            {isLowAvailability && (
                              <Text
                                fontSize="$3"
                                $gtMobile={{ fontSize: '$4' }}
                                color="$white"
                                backgroundColor="$red6"
                                borderRadius="$4"
                                paddingHorizontal="$1">
                                {getString('redSkySearch.results.card.fare.left').replace(
                                  '{{availableSeatCount}}',
                                  `${fare.availableSeatCount}`
                                )}
                              </Text>
                            )}
                            {isAvailable ? (
                              <Text fontSize="$4" $gtMobile={{ fontSize: '$7' }}>
                                <strong>{`${formatPoints({ points: fare.points })} Pts`}</strong>
                              </Text>
                            ) : (
                              <Text fontSize="$3" $gtMobile={{ fontSize: '$4' }}>
                                {getString('redSkySearch.results.card.fare.unavailable')}
                              </Text>
                            )}
                          </XStack>
                        </YStack>
                        {isAvailable && <Icon.ChevronDown color="$black" size="$2" />}
                      </XStack>
                    )
                  })}
                </XStack>
              </>
            )}
          </YStack>
        </Accordion.Trigger>
      </Accordion.Header>
      <AnimatePresence>
        <Accordion.Content borderRadius="$4" unstyled exitStyle={{ opacity: 0 }} backgroundColor="$white">
          {gtTabletPortrait ? (
            <XStack paddingLeft="$3" marginBottom="$3">
              {seatTypeCards}
            </XStack>
          ) : (
            <ScrollView horizontal paddingLeft="$2" marginBottom="$2" $gtMobile={{ paddingLeft: '$3', marginBottom: '$3' }}>
              {seatTypeCards}
            </ScrollView>
          )}
        </Accordion.Content>
      </AnimatePresence>
    </Accordion.Item>
  )
}
