import { useAppSelector } from '@/redux/hooks/hooks'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { useQuery } from '@tanstack/react-query'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { apiGetEarnRewards, apiGetSpendRewards } from '@vrw/data/src/api/discovery.api'
import { RewardCategory } from '@vrw/data/src/redux/categories/types'

export const useRewards = (isEarn: boolean, categories: RewardCategory[]) => {
  const viewingRegion = localeToRegion(getLocale())
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  const rewardsQuery = useQuery({
    queryKey: [
      'rewards',
      {
        region: viewingRegion,
        isLoggedIn: isAuthenticated,
        categories,
        isEarn,
      },
    ],
    queryFn: async () =>
      isEarn
        ? await apiGetEarnRewards({
            isLoggedIn: isAuthenticated,
            categories: categories,
          })
        : await apiGetSpendRewards({
            isLoggedIn: isAuthenticated,
            categories: categories,
          }),
  })

  if (rewardsQuery.isError) {
    console.error('useRewards.ts - error fetching earn rewards:', { error: rewardsQuery.error })
  }

  return {
    data: rewardsQuery.data ?? [],
    isLoading: rewardsQuery.isLoading,
    isError: rewardsQuery.isError,
  }
}
