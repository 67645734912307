import { get } from '../utils/httpClient'
import { getRedDataApiPaths, getRedDataConfig } from '../config'
import { Reward, RewardResponse, RewardTags } from '../redux/rewards/types'
import { RewardEarn, RewardEarnResponse } from '../redux/rewardsEarn/types'
import { CategoriesResponse, RewardCategory } from '../redux/categories/types'
import { CategoriesParameters } from './types'
import { RewardsSort } from '../redux/discovery/types'
import { SearchResponse } from '../redux/search/types'

const LIMIT = 8

interface ApiGetEarnRewardsParams {
  isLoggedIn: boolean
  categories: RewardCategory[]
  limit?: number
  offset?: number
  sort?: RewardsSort
}

/**
 * Fetches earn rewards based on provided parameters
 *
 * @param {Object} params - The request parameters
 * @param {boolean} params.isLoggedIn - Whether the user is logged in
 * @param {RewardCategory[]} params.categories - Array of category objects to filter rewards
 * @param {number} [params.limit=0] - Maximum number of rewards to return (0 means no limit)
 * @param {RewardsSort} [params.sort] - Sort order for the rewards
 * @returns {Promise<RewardEarn[]>} Array of earn rewards
 */
export const apiGetEarnRewards = async ({ isLoggedIn, categories, limit = 0, sort }: ApiGetEarnRewardsParams) => {
  const apiUrl = isLoggedIn
    ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN
    : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC

  const categoryNames = categories.map(({ categoryName }) => categoryName)
  const queryString = getApiUrlParameters({ categoriesData: { categories: categoryNames }, limit, sort, enableVariantGrouping: true })
  return (await get(`${apiUrl}?${queryString}`, isLoggedIn)).rewards as RewardEarn[]
}

/**
 * Fetches spend rewards based on provided parameters
 *
 * @param {Object} params - The request parameters
 * @param {boolean} params.isLoggedIn - Whether the user is logged in
 * @param {RewardCategory[]} params.categories - Array of category objects to filter rewards
 * @param {number} [params.limit=0] - Maximum number of rewards to return (0 means no limit)
 * @param {RewardsSort} [params.sort] - Sort order for the rewards
 * @returns {Promise<RewardEarn[]>} Array of spend rewards
 */
export const apiGetSpendRewards = async ({ isLoggedIn, categories, limit = 0, sort }: ApiGetEarnRewardsParams) => {
  const apiUrl = isLoggedIn
    ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND
    : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC

  const categoryNames = categories.map(({ categoryName }) => categoryName)
  const queryString = getApiUrlParameters({ categoriesData: { categories: categoryNames }, limit, sort, enableVariantGrouping: true })
  return (await get(`${apiUrl}?${queryString}`, isLoggedIn)).rewards as Reward[]
}

/**
 * Fetches earn categories from the appropriate API endpoint based on authentication status
 *
 * @param {boolean} isLoggedIn - Whether the user is logged in
 * @returns {Promise<CategoriesResponse>} Categories data including categories and category cost counts
 */
export const apiGetEarnCategories = async (isLoggedIn: boolean) => {
  const apiUrl = isLoggedIn
    ? getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN
    : getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN_PUBLIC

  const queryString = getApiUrlParameters()
  return (await get(`${apiUrl}?${queryString}`, isLoggedIn)) as CategoriesResponse
}

/**
 * Fetches spend categories from the appropriate API endpoint based on authentication status
 *
 * @param {boolean} isLoggedIn - Whether the user is logged in
 * @returns {Promise<CategoriesResponse>} Categories data including categories and category cost counts
 */
export const apiGetSpendCategories = async (isLoggedIn: boolean) => {
  const apiUrl = isLoggedIn ? getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES : getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_PUBLIC

  const queryString = getApiUrlParameters()
  return (await get(`${apiUrl}?${queryString}`, isLoggedIn)) as CategoriesResponse
}

export const apiGetAllCategoriesAndCategoryCostCounts = async () => {
  const queryString = getApiUrlParameters({ enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES}?${queryString}`, true)) as CategoriesResponse
}

export const apiGetAllCategoriesEarn = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN}?${queryString}`, true)).categories as RewardCategory[]
}

/** @deprecated use apiGetEarnRewards() */
export const apiGetEarnRewardsByCategory = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categoriesData: { categories }, limit })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategory = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categoriesData: { categories }, limit, enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCount = async (
  categoriesData: CategoriesParameters,
  sort?: RewardsSort,
  isBrands?: boolean
) => {
  const queryString = getApiUrlParameters({ categoriesData, sort, enableVariantGrouping: true, isBrands })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetAllCategoriesPublic = async () => {
  const queryString = getApiUrlParameters({ enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_PUBLIC}?${queryString}`)) as CategoriesResponse
}

export const apiGetAllCategoriesEarnPublic = async () => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_CATEGORIES_EARN_PUBLIC}?${queryString}`)).categories as RewardCategory[]
}

export const apiGetSearchByKeyword = async (keyword: string) => {
  const queryString = getApiUrlParameters({ keyword, enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_SEARCH}?${queryString}`, true)) as SearchResponse
}

/** @deprecated use apiGetEarnRewards() */
export const apiGetEarnRewardsByCategoryPaginated = async ({
  isPublicEndpoint,
  categories,
  limit,
  offset,
}: {
  isPublicEndpoint: boolean
  categories: string[]
  limit: number
  offset: number
}) => {
  const queryString = getApiUrlParameters({ categoriesData: { categories } })
  return (await get(
    `${
      isPublicEndpoint ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN
    }?limit=${limit}&offset=${offset}${queryString ? `&${queryString}` : ''}`,
    isPublicEndpoint ? false : true
  )) as RewardEarnResponse
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCountPaginated = async ({
  isPublicEndpoint,
  categoriesData,
  limit,
  offset,
  sort,
  isBrands,
}: {
  isPublicEndpoint: boolean
  categoriesData: CategoriesParameters
  limit: number
  offset: number
  sort?: RewardsSort
  isBrands?: boolean
}) => {
  const queryString = getApiUrlParameters({ categoriesData, sort, enableVariantGrouping: true, isBrands })

  return (await get(
    `${
      isPublicEndpoint ? getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC : getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND
    }?limit=${limit}&offset=${offset}${queryString ? `&${queryString}` : ''}`,
    isPublicEndpoint ? false : true
  )) as RewardResponse
}

/** @deprecated use apiGetEarnRewards() */
export const apiGetEarnRewardsByCategoryPublic = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categoriesData: { categories }, limit })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC}?${queryString}`)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategoryPublic = async (categories: string[], limit = 0) => {
  const queryString = getApiUrlParameters({ categoriesData: { categories }, limit, enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}?${queryString}`)).rewards as RewardEarn[]
}

export const apiGetSpendRewardsByCategoryAndCategoryCostCountPublic = async (
  categoriesData: CategoriesParameters,
  sort?: RewardsSort,
  isBrands?: boolean
) => {
  const queryString = getApiUrlParameters({ categoriesData, sort, enableVariantGrouping: true, isBrands })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}?${queryString}`)).rewards as Reward[]
}

// **** getRewardById **** //
export const apiGetRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters({ enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}/${rewardId}?${queryString}`, true)) as Reward
}

export const apiGetEarnRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}/${rewardId}?${queryString}`, true)) as RewardEarn
}

export const apiGetPublicRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters({ enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND_PUBLIC}/${rewardId}?${queryString}`)) as Reward
}

export const apiGetPublicEarnRewardById = async (rewardId: string) => {
  const queryString = getApiUrlParameters()
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN_PUBLIC}/${rewardId}?${queryString}`)) as RewardEarn
}

export const getApiUrlParameters = (params?: {
  categoriesData?: CategoriesParameters
  sort?: RewardsSort
  limit?: number
  keyword?: string
  tag?: string
  enableVariantGrouping?: boolean
  isBrands?: boolean
}) => {
  const { categoriesData, sort, limit, keyword, tag, enableVariantGrouping, isBrands } = params ?? {}
  const viewingRegion = getRedDataConfig().getViewingRegion()

  return [
    categoriesData?.categories?.map((category) => `categoryName=${encodeURIComponent(category)}`).join('&'),
    categoriesData?.categoryCostCounts?.map((categoryCostCount) => `costRangeId=${encodeURIComponent(categoryCostCount)}`).join('&'),
    sort ? `sort=${sort}` : '',
    tag ? `tag=${tag}` : '',
    isBrands ? 'tag=visibility.brandPageOnly' : '',
    limit && limit > 0 ? `limit=${limit}` : '',
    `viewingRegion=${viewingRegion}`,
    keyword ? `keyword=${encodeURIComponent(keyword)}` : '',
    enableVariantGrouping ? 'enableVariantGrouping=true' : '',
  ]
    .filter(Boolean)
    .join('&')
}

export const apiGetLatestEarnRewards = async () => {
  const queryString = getApiUrlParameters({ sort: RewardsSort.CreatedDate, limit: LIMIT })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetLatestSpendRewards = async () => {
  const queryString = getApiUrlParameters({ sort: RewardsSort.CreatedDate, limit: LIMIT, enableVariantGrouping: true })

  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}

export const apiGetPopularEarnRewards = async () => {
  const queryString = getApiUrlParameters({ limit: LIMIT, tag: RewardTags.Popular })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_EARN}?${queryString}`, true)).rewards as RewardEarn[]
}

export const apiGetPopularSpendRewards = async () => {
  const queryString = getApiUrlParameters({ limit: LIMIT, tag: RewardTags.Popular, enableVariantGrouping: true })
  return (await get(`${getRedDataApiPaths().RED_API_DISCOVERY_REWARDS_SPEND}?${queryString}`, true)).rewards as Reward[]
}
