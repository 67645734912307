import React from 'react'
import { Button, ButtonProps, Icon } from '@red-ui/components'

type Props = Pick<ButtonProps, 'onPress' | 'children'>

export const QuestionOptionButton = ({ onPress, children }: Props) => (
  <Button
    height="$5"
    chromeless
    borderColor="$black3"
    onPress={onPress}
    color="$black8"
    justifyContent="space-between"
    iconAfter={<Icon.ChevronRight />}
    textProps={{
      fontSize: '$3',
      $gtTabletPortrait: {
        fontSize: '$4',
      },
    }}
    hoverStyle={{ backgroundColor: '$black1', borderColor: '$black3' }}
    pressStyle={{ backgroundColor: '$black2', borderColor: '$black3' }}>
    {children}
  </Button>
)
