import React, { useState } from 'react'
import { getString } from '@vrw/data'
import { PATHS } from '@/router/paths'
import { useRedNavigate } from '@/dataImplementation/useRedNavigate'
import { OnChangeType, SearchForm, SearchFormKey, useSearchForm } from './useSearchForm'
import { generateSearchQueryString } from '../../helpers/searchQueryParams'
import { FlightSearchForm } from '../FlightSearchForm'
import { QuestionForm } from '../QuestionForm'
import { SideSheet } from '../SideSheet'
import { ScrollView, YStack, Text } from '@red-ui/components'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/style.css'
import './picker-override.css'
import { formatDateForDisplay } from '../../helpers/dateHelpers'

export type Props = {
  mainSheetOpen: boolean
  onCloseMainSheet: (value: boolean) => void
  mainSheetTitle?: string
  editMode?: boolean
  initData?: SearchForm | null
}

export const fields = ['departure', 'destination', 'date', 'passengers']

export const SearchSideSheets = ({ mainSheetOpen, onCloseMainSheet, mainSheetTitle, editMode = false, initData }: Props) => {
  const [openSheet, setOpenSheet] = useState<SearchFormKey | null>(null)
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0)

  const { form, onChange: onChangeField, onReset } = useSearchForm(initData)

  const redNavigate = useRedNavigate()

  const handleMainClose = () => {
    if (openSheet) handleSubSheetClose()
    onCloseMainSheet(false)
    onReset()
  }

  const onChange: OnChangeType = (field, value) => {
    if (!editMode && currentQuestionIdx < fields.length - 1) {
      setCurrentQuestionIdx(currentQuestionIdx + 1)
    }

    onChangeField(field, value)
  }

  const onChangeAndClose = (field: SearchFormKey) => (airport: SearchForm[SearchFormKey]) => {
    handleSubSheetClose()
    onChange(field, airport)
  }

  const handleSubSheetClose = () => {
    setOpenSheet(null)
  }

  const onSheetOpen = (type: SearchFormKey) => () => {
    setOpenSheet(type)
  }

  const onSubmitForm = () => {
    const searchQueryStr = generateSearchQueryString(form)
    onCloseMainSheet(false)
    redNavigate(`${PATHS.REDSKY_SEARCH_RESULTS}?${searchQueryStr}`)
  }

  return (
    <>
      <SideSheet open={mainSheetOpen} onClose={handleMainClose} ariaLabel={getString('redSkySearch.sidesheet.questions.ariaLabel')}>
        <QuestionForm
          onClose={handleMainClose}
          onSubSheetOpen={onSheetOpen}
          title={mainSheetTitle}
          editMode={editMode}
          form={form}
          onChange={onChange}
          onSubmit={onSubmitForm}
          currentQuestionIdx={currentQuestionIdx}
          onChangeQuestionIdx={setCurrentQuestionIdx}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'departure'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.departure.ariaLabel')}>
        <FlightSearchForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.departure.label')}
          info={getString('redSkySearch.question.departure.input.info')}
          onSelect={onChangeAndClose('departure')}
          value={form.departure?.airportName}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'destination'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.destination.ariaLabel')}>
        <FlightSearchForm
          onClose={handleSubSheetClose}
          title={getString('redSkySearch.question.destination.label')}
          info={getString('redSkySearch.question.destination.input.info')}
          onSelect={onChangeAndClose('destination')}
          value={form.destination?.airportName}
        />
      </SideSheet>
      <SideSheet
        open={openSheet === 'date'}
        onClose={handleSubSheetClose}
        hasOverlay={false}
        ariaLabel={getString('redSkySearch.sidesheet.date.ariaLabel')}>
        <SideSheet.Header iconPosition="left" title={getString('redSkySearch.question.date.title')} onClose={handleSubSheetClose} />
        <YStack backgroundColor="$red6" padding="$2" alignItems="center">
          <Text tag="strong" color="$white">
            {getString('redSkySearch.question.date.label')}
          </Text>
          <Text color="$white">{formatDateForDisplay(form.date)}</Text>
        </YStack>
        <ScrollView
          contentContainerStyle={{
            paddingTop: '$4',
            paddingBottom: '$3',
            paddingHorizontal: '$3',
          }}>
          <DayPicker
            animate
            timeZone="UTC"
            autoFocus
            required
            ISOWeek
            hideNavigation
            // NOTE: For now we limit to the current year
            disabled={{ before: new Date(), after: new Date(`${new Date().getFullYear()}-12-31`) }}
            numberOfMonths={12}
            mode="single"
            styles={{
              months: {
                justifyContent: 'center',
              },
              month: {
                width: '100%',
              },
              month_grid: {
                width: '100%',
              },
              day_button: {
                margin: 'auto',
              },
            }}
            selected={form.date}
            onSelect={onChangeAndClose('date')}
          />
        </ScrollView>
      </SideSheet>
    </>
  )
}
