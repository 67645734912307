import { useAppSelector } from '@/redux/hooks/hooks'
import { getLocale, localeToRegion } from '../../dataImplementation/viewingRegionWeb'
import { useQuery } from '@tanstack/react-query'
import { getIsAuthenticated } from '@vrw/data/src/redux/auth/selectors'
import { apiGetEarnCategories, apiGetSpendCategories } from '@vrw/data/src/api/discovery.api'

export const useCategories = (isEarn: boolean) => {
  const viewingRegion = localeToRegion(getLocale())
  const isAuthenticated = useAppSelector(getIsAuthenticated)

  const categoriesQuery = useQuery({
    queryKey: [
      'rewardsCategories',
      {
        region: viewingRegion,
        isLoggedIn: isAuthenticated,
        isEarn,
      },
    ],
    queryFn: async () => (isEarn ? await apiGetEarnCategories(isAuthenticated) : await apiGetSpendCategories(isAuthenticated)),
  })

  if (categoriesQuery.isError) {
    console.error('useEarnDiscovery.ts - error fetching categories:', { error: categoriesQuery.error })
  }

  return {
    data: categoriesQuery.data ?? {},
    isLoading: categoriesQuery.isLoading,
    isError: categoriesQuery.isError,
  }
}
