import React, { FC } from 'react'

import { RewardsListPrivateSpend } from '../../components'
import { RewardsList } from '../../components/RewardsList/RewardsList'
import { useAppSelector } from '@/redux/hooks/hooks'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { DiscoveryFeed } from '../../components/DiscoveryFeed'
import { useRewards } from '../../query/rewards/useRewards'

export const SpendPrivate: FC = () => {
  const { data: rewards } = useRewards(false, [])
  const newFiltersFlag = useAppSelector(useFeatureSelector(FeatureName.NEW_FILTERS))
  return (
    <DiscoveryFeed title="Ways to Spend Virgin Points" isEarn={false}>
      {newFiltersFlag ? <RewardsList rewards={rewards} isLoading={false} /> : <RewardsListPrivateSpend />}
    </DiscoveryFeed>
  )
}
