import React, { useEffect, useState } from 'react'
import { Reward } from '@vrw/data/src/redux/rewards/types'
import { RewardEarn as RewardEarnTile } from '../TileLister/RewardsEarnTile/convertCmsEarnsToTiles'
import { useAppSelector } from '@/redux/hooks/hooks'
import { getIsLoadingWishlist, getWishlistRewards } from '@vrw/data/src/redux/wishlist/selectors'
import { doAddRewardToWishlist, doRemoveRewardFromWishlist } from '@vrw/data/src/redux/wishlist/dispatchers'
import { TileLister, TilesType } from '../TileLister'
import formatEarnTiles from '../TileLister/RewardsEarnTile/formatEarnTiles'
import { formatRewardTiles } from '../TileLister/RewardsTile/formatRewardTiles'
import { RewardEarn } from '@vrw/data/src/redux/rewardsEarn/types'
import { LoadSpinner } from '..'
import { Text, View, VisuallyHidden } from '@red-ui/components'

export type RewardsListProps = {
  rewards: (Reward | RewardEarn)[]
  isLoading: boolean
}

export const RewardsList = ({ rewards, isLoading }: RewardsListProps) => {
  const wishlist = useAppSelector(getWishlistRewards)
  const isLoadingWishlist = useAppSelector(getIsLoadingWishlist)

  const [rewardTiles, setRewardTiles] = useState<TilesType>([])

  const isRewardEarnType = (reward: Reward | RewardEarn): reward is RewardEarn => {
    return 'type' in reward && reward.type?.toLowerCase() === 'earn'
  }

  useEffect(() => {
    setRewardTiles(
      rewards.map((reward) => {
        return isRewardEarnType(reward) ? formatEarnTiles(reward as RewardEarnTile) : formatRewardTiles(reward)
      })
    )
  }, [rewards])

  return isLoading ? (
    <View justifyContent="center" flex={1} alignItems="center" minHeight={500}>
      <LoadSpinner />
    </View>
  ) : (
    <>
      <VisuallyHidden tag="output">
        <Text>{`${rewards.length} rewards available`}</Text>
      </VisuallyHidden>
      <TileLister
        tiles={rewardTiles}
        showWishListIcons={true}
        wishlist={wishlist}
        isLoadingWishlist={isLoadingWishlist}
        doRemoveRewardFromWishlist={doRemoveRewardFromWishlist}
        doAddRewardToWishlist={doAddRewardToWishlist}
      />
    </>
  )
}
