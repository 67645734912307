import COUNTRY_CODES from './data/countryCodes.json'
import CITIZENSHIP from './data/citizenship.json'
import GENDERS from './data/genders.json'

export { COUNTRY_CODES, CITIZENSHIP, GENDERS }
export const generateYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  for (let i = currentYear - 100; i <= currentYear; i++) {
    years.push({ value: i.toString(), label: i.toString() })
  }
  return years.reverse()
}

export const MONTHS = Array.from({ length: 12 }, (_, i) => {
  const value = (i + 1).toString()
  return { value, label: value.padStart(2, '0') }
})

export const generateDays = () => {
  const days = []
  for (let i = 1; i <= 31; i++) {
    const value = i.toString()
    days.push({ value, label: value.padStart(2, '0') })
  }
  return days
}
