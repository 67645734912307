import React, { FC } from 'react'

import { RewardsListPrivateEarn } from '../../components'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { useAppSelector } from '../../redux/hooks/hooks'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { RewardsList } from '../../components/RewardsList/RewardsList'
import { DiscoveryFeed } from '../../components/DiscoveryFeed'
import { useRewards } from '../../query/rewards/useRewards'

export const EarnPrivate: FC = () => {
  const { data: rewards, isLoading } = useRewards(true, [])
  const isNewFiltersFlagEnabled = useAppSelector(useFeatureSelector(FeatureName.NEW_FILTERS))
  return (
    <DiscoveryFeed title="Ways to Earn Virgin Points" isEarn>
      {isNewFiltersFlagEnabled ? <RewardsList rewards={rewards} isLoading={isLoading} /> : <RewardsListPrivateEarn />}
    </DiscoveryFeed>
  )
}
