import { createSearchParams } from 'react-router-dom'
import { formatDateToShortFormat } from './dateHelpers'
import { SearchForm } from '../components/SearchSideSheets/useSearchForm'

const passengersQueryMap = {
  a: 'adults',
  t: 'youngAdults',
  c: 'children',
  i: 'infant',
}

const defaultPassengersResultObj = {
  adults: 1,
  youngAdults: 0,
  children: 0,
  infant: 0,
}

export const convertPassengersQueryStrToObject = (passengersQueryString: string) => {
  const matches = passengersQueryString.match(/[a-z]\d/g)

  if (!matches) return defaultPassengersResultObj

  return matches.reduce(
    (acc, match) => {
      const key = match.charAt(0)
      const value = parseInt(match.charAt(1), 10)
      const propertyName = passengersQueryMap[key as keyof typeof passengersQueryMap]

      if (propertyName) acc[propertyName as keyof typeof defaultPassengersResultObj] = value

      return acc
    },
    { ...defaultPassengersResultObj }
  )
}

export const generateSearchQueryObj = (form: SearchForm) => {
  const { departure, destination, date, adults, youngAdults, children, infant } = form

  if (!departure || !destination || !date) return null

  const passengersQueryStr = Object.entries({
    a: adults,
    t: youngAdults,
    c: children,
    i: infant,
  })
    .map(([key, value]) => `${key}${value}`)
    .join('')

  return {
    origin: departure.code,
    destination: destination.code,
    departing: formatDateToShortFormat(date),
    passengers: passengersQueryStr,
  }
}

export const generateSearchQueryString = (form: SearchForm) => {
  const searchQueryObj = generateSearchQueryObj(form)
  if (!searchQueryObj) return ''
  return createSearchParams(searchQueryObj).toString()
}
