/*
 * NEW VARIABLES FOR RESPONSIVE GRID
 * RESPONSIVE DESIGN SPECS: https://zpl.io/mDXpMq3
 */
export const responsiveGrid = {
  sideMargins: {
    hiRes: 60,
    desktop: 60,
    tablet: 44,
    mobile: 16,
  },
  columnSpacing: {
    hiRes: 24,
    desktop: 24,
    tablet: 24,
    mobile: 16,
  },
} as const

/* PRE-EXISTING VARIABLES */
export const tileSize = {
  desktopWidth: 424,
  minHeight: 424,
  margin: 24,
  width: 348,
} as const

export const newTileSize = {
  desktopWidth: 312,
  minHeight: 362,
  margin: 24,
  width: 312,
} as const

export const spacing = {
  pageMargins: {
    hiRes: 60,
    desktop: 60,
    tablet: 24,
    mobile: 15,
  },
  verticalStandard: 30,
  zero: {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  },
  small: {
    mobile: 24,
    tablet: 32,
    desktop: 40,
  },
  medium: {
    mobile: 32,
    tablet: 40,
    desktop: 64,
  },
  xLarge: {
    mobile: 40,
    tablet: 64,
    desktop: 88,
  },
} as const

export interface Spacing {
  mobile?: number
  tablet?: number
  desktop?: number
}

export enum color {
  brandPrimary = '#e10a0a',
  redHover = '#bf0808',
  greyHover = '#f5f5f5',
  grey01 = '#E5dEE6',
  grey02 = '#CCC',
  grey03 = '#979797',
  grey04 = '#e8e8e8',
  grey05 = '#f3f3f3',
  grey06 = '#e6e4e4',
  grey07 = '#6f6f6f',
  greyTransparent = 'rgba(0, 0, 0, 0.1)',
  greyTransparent2 = 'rgba(0, 0, 0, 0.5)',
  secondaryButtonBorder = '#E3E3E3',
  textHeader = '#1d1d1d',
  textStandard = '#1d1d1d',
  caption = '#808080',
  greyBackground = '#f7f7f7',
  pinkBackground = '#fff2f2',
  darkPinkBackground = '#fce6e6',
  lightPinkBackground = '#fef4f4',
  white = '#FFF',
  whiteRGB = '255, 255, 255',
  darkGrey = '#595959',
  authorGrey = '#999999',
  black = '#000',
  blackRGB = '0, 0, 0',
  blackUnderline = '#1a1a1a',
  successGreen = '#32996d',
  errorRed = '#f44336',
  focusedInputBackground = '#00000005',
  secondaryRedButtonHover = '#e2e2e2',
  rgbaRedHover = 'rgba(255, 255, 255, 0.5)',
  divider = 'rgb(132, 132, 132)',
  positivePointsGreen = '#097949',
  imageCreditOverlayBackground = 'rgba(0, 0, 0, 0.5)',
  imageCreditOverlay = '#ffffff',
  imageCredit = '#999999',
  lightGrey = '#d8d8d8',
  lighterGrey = '#737373',
  lighterGreen = '#ddebdf',
  secondaryDarkPurple = '#5c2244',
  turquoiseGreen = '#a9d4bb',
}

export const layout = {
  copyWidths: {
    tablet: 534,
    desktop: 648,
  },
  heroWidth: {
    tablet: 722,
    desktop: 872,
  },
} as const

export enum FontFamilies {
  utopiaStd = 'utopia-std, serif',
  barlow = 'Barlow, sans-serif',
}

export const squareImageSizes = {
  mobile: '256px',
  tablet: '352px',
  desktop: '424px',
}

export enum ZIndex {
  carouselBoxShadow = 1,
  carouselButton = 2,
  Banner = 10,
  Modal = 11,
  Menu = 12,
  UserMenu = 13,
}

export const toastBgColors: { [keys: string]: string } = {
  error: '#fff6f5',
  warning: '#fffaf5',
  success: '#f7fff8',
  information: '#f3f3f3',
}

export const toastColors: {
  [keys: string]: { title: string; description: string }
} = {
  error: { title: '#9f0f10', description: '#5c1515' },
  warning: { title: '#af6d15', description: '#654318' },
  success: { title: '#2a664c', description: '#244d3b' },
  information: { title: '#1d1d1d', description: '#1d1d1d' },
}
