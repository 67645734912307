import React from 'react'
import { Card, Separator, Button, Text, XStack } from '@red-ui/components'
import { SeatTypeInfo } from './SeatTypeInfo'
import { getString } from '@vrw/data'
import { getTrackingId } from '../../helpers/getTrackingId'
import { formatPoints } from '@vrw/data/src/utils/formatters'

type Props = {
  onSelect: () => void
}

export const SeatTypeCard = ({ onSelect }: Props) => {
  return (
    <Card
      minWidth={284}
      flex={1}
      backgroundColor="$white"
      borderWidth={1}
      padding="$2"
      marginRight="$2"
      $gtMobile={{ padding: '$3', marginRight: '$3', minWidth: 254 }}>
      <SeatTypeInfo />
      <Separator marginVertical="$2" $gtMobile={{ marginVertical: '$3' }} />
      <XStack
        justifyContent="space-between"
        alignItems="flex-end"
        $gtMobile={{ flexDirection: 'column', alignItems: 'flex-start' }}
        $gtTabletLandscape={{ flexDirection: 'row' }}>
        <Text
          marginRight="$2"
          fontSize="$3"
          $gtMobile={{ fontSize: '$5', marginBottom: '$1' }}
          $gtTabletLandscape={{ fontSize: '$6', marginBottom: '$0' }}>
          {getString('redSkySearch.results.card.points.label.onewaytrip')}
        </Text>
        <strong>
          <Text lineHeight="$7" fontSize="$7" $gtMobile={{ fontSize: '$8' }} $gtTabletLandscape={{ fontSize: '$9' }}>
            {`${formatPoints({ points: 75000 })} `}
            <Text>{getString('redSkySearch.results.card.points')}</Text>
          </Text>
        </strong>
      </XStack>
      <Button
        id={getTrackingId('flight-card', getString('redSkySearch.results.card.cta'))}
        height="$6"
        onPress={onSelect}
        aria-label={getString('redSkySearch.results.card.cta')}
        marginTop="$3">
        {getString('redSkySearch.results.card.cta')}
      </Button>
    </Card>
  )
}
