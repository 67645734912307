import React from 'react'

import { ExclusiveRewardsSection } from '@/components/ExclusiveRewardsSection'
import { useAppSelector } from '@/redux/hooks/hooks'
import { useFeatureSelector } from '@vrw/data/src/redux/features/features.selectors'
import { FeatureName } from '@vrw/data/src/redux/features/features.types'
import { CurvedContainer } from '@/components/CurvedContainer'
import { MobileAppsSplash, RewardsFilterWrapper } from '@/components'
import { PillItem, styled, View } from '@red-ui/components'
import { color } from '@/style/variables'
import { DiscoveryHeader } from './DiscoveryHeader'
import { CategoriesResponse } from '@vrw/data/src/redux/categories/types'
import { useCategories } from '@/query/rewards/useCategories'

interface DiscoveryFeedProps {
  title: string
  isEarn: boolean
  isPublic?: boolean
  children?: React.ReactNode
}

const PinkBackground = styled(View, {
  height: '210px',
  marginTop: '130px',
  marginBottom: '-350px',
  backgroundImage: 'linear-gradient(to bottom, #fce6e6, #fef4f4 57%, #ffffff)',
  $gtMobile: {
    height: '200px',
    marginBottom: '-330px',
  },
  $gtDesktop: {
    height: '200px',
    marginBottom: '-350px',
  },
})

const ALL_KEY = 'all'

const transformCategoriesObject = (categoriesData: CategoriesResponse) => {
  let categories = [] as PillItem[]
  if (categoriesData.categories && categoriesData.categoryCostCounts) {
    categories = [
      { name: 'All', count: categoriesData.categoryCostCounts[ALL_KEY][ALL_KEY] ?? 0 },
      ...categoriesData.categories.map((category) => ({
        name: category.categoryName,
        count: categoriesData.categoryCostCounts?.[category.categoryName]?.[ALL_KEY] ?? 0,
      })),
    ]
  }
  return categories
}

export const DiscoveryFeed = ({ title, children, isEarn, isPublic = false }: DiscoveryFeedProps) => {
  const newFiltersFlag = useAppSelector(useFeatureSelector(FeatureName.NEW_FILTERS))
  const { data: categoriesData } = useCategories(isEarn)

  return (
    <>
      {newFiltersFlag ? (
        <>
          <DiscoveryHeader title={title} categories={transformCategoriesObject(categoriesData)} />
          {!isPublic && <ExclusiveRewardsSection />}
          {children}
        </>
      ) : (
        <>
          <CurvedContainer position="bottom" height={65} offset={140} overlayColor={color.darkPinkBackground}>
            {!isPublic && <ExclusiveRewardsSection />}
            <RewardsFilterWrapper isEarn={isEarn} isPublic={isPublic} />
          </CurvedContainer>
          <PinkBackground />
          {children}
        </>
      )}
      <MobileAppsSplash />
    </>
  )
}
